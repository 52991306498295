import React, { useEffect } from "react"
import { useState } from "react"
import tw from "twin.macro"
import Button from "../Button"
import { Input } from "../Form"
import Textarea from "../Form/Textarea"

const ContactForm = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleSubmit = async e => {
    e.preventDefault()
    setSuccess(false)
    setEmailError(false)
    setSubmitting("Submitting")

    // Validate email field
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    // If is not valid email
    if (!re.test(String(email).toLowerCase())) {
      setEmailError("A valid email is required to send a message.")
      setSubmitting(false)
      // If is valid email
    } else {
      const res = await fetch(`${process.env.GATSBY_EMAIL_SERVICE_URL}/email`, {
        method: "post",
        headers: {
          "x-api-key": process.env.GATSBY_EMAIL_SERVICE_KEY,
        },
        body: JSON.stringify({
          sheet_title: "contact",
          email: email,
          name: name,
          message: message,
        }),
      })
      const json = await res.json()
      if (res.status === 200) {
        setSubmitting(false)
        setSuccess(json.message)
        setEmail("")
        setName("")
        setMessage("1234")
      }
      if (res.status === 500) {
        setEmailError(json.message)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} novalidate="novalidate">
      <Input
        type="text"
        id="name"
        label="Name"
        name="name"
        autoComplete="name"
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Input
        type="email"
        id="email"
        label="Email"
        email="email"
        autoComplete="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        error={emailError}
      />
      <Textarea
        label="Message"
        id="message"
        onChange={e => setMessage(e.target.value)}
        css={[tw`mb-8`]}
      >
        {message}
      </Textarea>
      <Button primary block type="submit" submitting={submitting}>
        {success || submitting || "Send"}
      </Button>
    </form>
  )
}

export default ContactForm
