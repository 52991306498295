import React from "react"
import tw from "twin.macro"
import Container from "../Container"
import EmailSubmissionForm from "../EmailSubmissionForm"

const NewsletterSignup = ({ data }) => {
  return (
    <section
      css={[
        tw`bg-gradient-to-tr from-green to-secondary py-16 md:py-24 -mt-10 -mb-10 transform -skew-y-3`,
      ]}
    >
      <Container css={[tw`transform skew-y-3`]}>
        <h2
          css={[tw`text-center mb-8 md:mb-12`]}
          data-sal="slide-up"
          data-sal-duration={400}
          data-sal-ease="ease"
        >
          {data.headline}
        </h2>
        <div
          css={[tw`max-w-screen-md mx-auto`]}
          data-sal="slide-up"
          data-sal-duration={400}
          data-sal-delay={200}
          data-sal-ease="ease"
        >
          <EmailSubmissionForm
            email_label={data.email_label}
            cta={data.cta}
            inline
            id="newsletter"
          />
        </div>
      </Container>
    </section>
  )
}

export default NewsletterSignup
