import React from "react"
import tw from "twin.macro"
import Container from "../Container"
import * as icons from "../Icons"

const Why = ({ data }) => {
  return (
    <section name="features" css={[tw`py-16 md:(pb-24 pt-40) bg-white`]}>
      <Container>
        <svg css={[tw`h-0`]}>
          <linearGradient id="iconGradient">
            <stop offset="0%" stop-color="#00aeef" />
            <stop offset="100%" stop-color="#00ff8f" />
          </linearGradient>
        </svg>
        <h2
          css={[tw`text-center mb-8 md:mb-12`]}
          data-sal="slide-up"
          data-sal-duration={400}
          data-sal-ease="ease"
        >
          {data.headline}
        </h2>
        <div css={[tw`grid md:grid-cols-3 gap-4`]}>
          {data.cards.map((card, i) => {
            const Icon = icons[card.icon]
            return (
              <div
                data-sal="slide-up"
                data-sal-duration={400}
                data-sal-delay={200 * i}
                data-sal-ease="ease"
              >
                <div
                  css={[
                    tw`h-full bg-primaryLight rounded-lg px-8 py-10 hover:(bg-primary text-white shadow-xl transition-all duration-200)`,
                    ":hover svg { fill: url(#iconGradient)!important }",
                  ]}
                >
                  {card.icon && (
                    <Icon css={[tw`w-16 h-16 transition-all duration-200`]} />
                  )}
                  <h3 css={[tw`font-bold text-base`]}>{card.title}</h3>
                  <p>{card.description}</p>
                </div>
              </div>
            )
          })}
        </div>
      </Container>
    </section>
  )
}

export default Why
