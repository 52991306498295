import React from "react"
import tw from "twin.macro"
import ContactForm from "../ContactForm"
import Container from "../Container"

const Contact = ({ data }) => {
  return (
    <section
      name="contact-us"
      css={[tw`bg-secondary pt-16 md:pt-40 pb-48 transform skew-y-6 -mb-20`]}
    >
      <Container css={[tw`transform -skew-y-6`]}>
        <div css={[tw`grid md:grid-cols-2 gap-16`]}>
          <div>
            <h2 css={[tw`mb-8 md:mb-4`]}>{data.headline}</h2>
            <p>{data.paragraph}</p>
          </div>
          <ContactForm />
        </div>
      </Container>
    </section>
  )
}

export default Contact
