import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import tw from "twin.macro"
import Container from "../Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"

const Explainer = ({ data }) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [swiper, setSwiper] = useState(null)

  const {
    files: { screenshots },
  } = useStaticQuery(graphql`
    {
      files: allFile(filter: { relativeDirectory: { eq: "screenshots" } }) {
        screenshots: nodes {
          childImageSharp {
            gatsbyImageData(width: 800)
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeSlide)
    }
  }, [activeSlide])

  useEffect(() => {
    const interval = setInterval(() => {
      const newSlide = activeSlide =>
        activeSlide + 1 < data.slides.length ? activeSlide + 1 : 0

      setActiveSlide(newSlide)
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  return (
    <section css={[tw`bg-primaryLight py-20 md:py-32`]}>
      <Container wide>
        <h2
          css={[tw`text-center mb-8 md:mb-12`]}
          data-sal="slide-up"
          data-sal-duration={400}
          data-sal-ease="ease"
        >
          {data.headline}
        </h2>
        <div>
          <nav
            data-sal="slide-up"
            data-sal-duration={400}
            data-sal-delay={200}
            data-sal-ease="ease"
            css={[
              tw`grid grid-cols-2 grid-rows-2 sm:flex sm:justify-center mb-8 text-center`,
            ]}
          >
            {data.slides.map((slide, i) => {
              const isActive = i === activeSlide
              return (
                <button onClick={() => setActiveSlide(i)}>
                  <h3
                    css={[
                      tw`border-b-4 border-transparent pb-1 mb-4 sm:mt-0 sm:py-2 mx-auto sm:mx-6 text-lg md:text-2xl`,
                      "max-width:100px; @media(min-width:640px){max-width: unset;}",
                      isActive && tw`border-secondary font-bold`,
                    ]}
                  >
                    {slide.title}
                  </h3>
                </button>
              )
            })}
          </nav>
          <section
            data-sal="slide-up"
            data-sal-duration={400}
            data-sal-delay={400}
            data-sal-ease="ease"
            css={[
              tw`relative h-80`,
              "@media(min-width:400px){height:16rem;} @media(min-width:640px){height:10rem;}",
            ]}
          >
            {data.slides.map((slide, i) => {
              const isActive = i === activeSlide
              return (
                <p
                  css={[
                    tw`text-center absolute left-1/2 opacity-0 transform -translate-x-1/2 translate-y-2 transition duration-500 max-w-screen-md w-full`,
                    isActive && tw`opacity-100 translate-y-0`,
                  ]}
                >
                  {slide.paragraph}
                </p>
              )
            })}
          </section>
        </div>
        <Swiper
          slidesPerView={1}
          onSlideChange={e => setActiveSlide(e.activeIndex)}
          onSwiper={setSwiper}
          activeIndex={activeSlide}
        >
          {data.slides.map((slide, i) => (
            <SwiperSlide>
              {({ isActive }) => (
                <div css={[tw`text-center`]}>
                  <GatsbyImage
                    placeholder="tracedSVG"
                    loading="eager"
                    image={getImage(screenshots[i])}
                    css={[
                      tw`shadow rounded-lg`,
                      // "width: 80vw;",
                      isActive && tw`shadow-lg`,
                    ]}
                  />
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <p>{data.share.headline}</p> */}
      </Container>
    </section>
  )
}

export default Explainer
