import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import tw from "twin.macro"
import Container from "../Container"
import EmailSubmissionForm from "../EmailSubmissionForm"

const BetaSignup = ({ data }) => {
  const { phoneImage } = useStaticQuery(graphql`
    {
      phoneImage: allFile(
        filter: { relativePath: { eq: "phone-beta@3x.png" } }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(width: 800, placeholder: TRACED_SVG)
          }
        }
      }
    }
  `)
  return (
    <section
      name="beta"
      css={[
        tw`bg-gradient-to-bl from-secondary to-green pt-28 pb-20 md:pb-40 transform -skew-y-6 animate-gradient-x`,
      ]}
    >
      <Container css={[tw`transform skew-y-6`]}>
        <div css={[tw`sm:grid sm:grid-cols-2`]}>
          <div
            css={[tw`relative`]}
            data-sal="slide-right"
            data-sal-duration={500}
            data-sal-easing="ease"
          >
            <GatsbyImage
              loading="lazy"
              image={getImage(phoneImage.nodes[0])}
              css={[
                tw`-ml-28 -top-64 sm:absolute sm:right-0 sm:-top-44 lg:-top-80`,
                "width:435px; @media (min-width: 768px){ width:600px; } @media (min-width: 1024px){ width:700px }",
              ]}
            />
          </div>
          <div css={[tw`-mt-72 sm:mt-0 relative z-20`]}>
            <div
              css={[tw`mb-8 text-center sm:text-left`]}
              dangerouslySetInnerHTML={{ __html: data.headline }}
              data-sal="slide-up"
              data-sal-duration={500}
              data-sal-easing="ease"
            ></div>
            <div
              data-sal="slide-up"
              data-sal-duration={500}
              data-sal-delay={100}
              data-sal-easing="ease"
            >
              <EmailSubmissionForm
                email_label={data.email_label}
                cta={data.cta}
                id="beta"
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default BetaSignup
