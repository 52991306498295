import React from "react"
import tw from "twin.macro"
import Container from "../Container"
import Team from "../Team"

const About = ({ data }) => {
  return (
    <section name="about" css={[tw`pt-32 pb-20 md:pb-32`]}>
      <Container>
        <div css={[tw`grid md:grid-cols-2`]}>
          <h2
            css={[tw`mb-8`]}
            data-sal="slide-right"
            data-sal-duration={400}
            data-sal-ease="ease"
          >
            {data.headline}
          </h2>
          <div
            data-sal="slide-left"
            data-sal-duration={400}
            data-sal-delay={200}
            data-sal-ease="ease"
          >
            <div
              dangerouslySetInnerHTML={{ __html: data.content }}
              css={[tw`mb-16`]}
            />
            {/* <h3 css={[tw`mb-8 font-bold text-center`]}>Our Team</h3> */}
            {/* <Team /> */}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default About
