import React from "react"
import tw from "twin.macro"
import Container from "../Container"

const Info = ({ data }) => {
  return (
    <section css={[tw`py-32`]}>
      <Container>
        <div css={[tw`grid md:grid-cols-2 gap-4 md:gap-16`]}>
          <div data-sal="slide-up" data-sal-duration={300}>
            <h2 css={[tw`mb-6`]} className="h3">
              {data.headline}
            </h2>
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration={500}
            data-sal-delay={200}
            data-sal-easing="ease"
          >
            <p css={[tw`text-xl`]}>{data.paragraph}</p>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Info
