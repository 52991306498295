import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import tw from "twin.macro"
import Container from "../Container"

const Hero = ({ data }) => {
  const { blank1, blank2, headline } = data
  const heroHeadlineSections = headline.split("_")

  const [blanks, setBlanks] = useState(["", ""])

  useEffect(() => {
    let timer = setInterval(
      () =>
        setBlanks([
          Math.floor(Math.random() * blank1.length),
          Math.floor(Math.random() * blank2.length),
        ]),
      2000
    )

    return () => {
      clearInterval(timer)
    }
  }, [])

  const { heroImage } = useStaticQuery(graphql`
    {
      heroImage: allFile(
        filter: { relativePath: { eq: "laptop-hero@3x.png" } }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(width: 800, placeholder: TRACED_SVG)
          }
        }
      }
    }
  `)

  return (
    <section name="home" css={[tw`py-16 mb-56 md:mb-0`]}>
      <Container>
        <div css={[tw`md:grid md:grid-cols-2 col-gap-32`]}>
          <div
            data-sal="slide-right"
            data-sal-delay={100}
            data-sal-duration={500}
            data-sal-easing="ease"
            css={[tw`max-w-full`]}
          >
            <h1 css={[tw`lg:text-7xl mb-4 z-30 xl:z-20 relative`]}>
              {heroHeadlineSections[0]}
              <div
                css={[
                  tw`bg-secondary bg-opacity-25 whitespace-nowrap relative h-12 md:h-20 lg:h-24 w-screen`,
                ]}
              >
                {blank1.map((blank, i) => {
                  const active = i === blanks[0]
                  return (
                    <span
                      css={[
                        tw`absolute opacity-0 transform translate-y-4 transition-all ease-in-out duration-500`,
                        active && tw`opacity-100 translate-y-0`,
                      ]}
                    >
                      {blank}
                    </span>
                  )
                })}
              </div>
              {heroHeadlineSections[1]}
              <div
                css={[
                  tw`bg-secondary bg-opacity-25 whitespace-nowrap relative h-12 md:h-20 lg:h-24 w-screen`,
                ]}
              >
                {blank2.map((blank, i) => {
                  const active = i === blanks[1]
                  return (
                    <span
                      css={[
                        tw`absolute opacity-0 transform translate-y-4 transition-all ease-in-out duration-500 delay-300`,
                        active && tw`opacity-100 translate-y-0`,
                      ]}
                    >
                      {blank}.
                    </span>
                  )
                })}
              </div>
            </h1>
            <p css={[tw`text-secondary mt-8 md:mt-0`]} className="h4">
              {data.subheadline}
            </p>
          </div>
          <div css={[tw`relative`]}>
            <div
              css={[tw`relative z-20`]}
              data-sal="slide-left"
              data-sal-delay={300}
              data-sal-duration={500}
              data-sal-easing="ease"
            >
              <GatsbyImage
                loading="lazy"
                image={getImage(heroImage.nodes[0])}
                css={[
                  tw`absolute top-20 lg:top-0 left-1/2 transform -translate-x-1/2 md:translate-x-0 md:left-0 z-10`,
                  "width:375px; @media(min-width:768px){width:575px;} @media(min-width:1024px){width:700px;}",
                ]}
              />
            </div>
            <div
              data-sal="slide-left"
              data-sal-delay={200}
              data-sal-duration={500}
              data-sal-easing="ease"
            >
              <div
                css={[
                  tw`absolute top-8 right-1/2 md:top-0 md:-right-48 transform translate-x-1/2 md:translate-x-0 bg-gradient-to-bl from-secondary to-blue rounded-full`,
                  "height:300px;width:300px; @media(min-width:768px){height:500px;width:500px;}",
                ]}
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Hero
