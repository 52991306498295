import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SocialLinks from "../components/SocialLinks"
import ContactForm from "../components/ContactForm"
import EmailSubmissionForm from "../components/EmailSubmissionForm"
import tw from "twin.macro"
import {
  Hero,
  Info,
  BetaSignup,
  Why,
  Social,
  Explainer,
  NewsletterSignup,
  Roadmap,
  About,
  Contact,
} from "../components/Home"

const IndexPage = ({ data }) => {
  const {
    hero,
    info,
    beta_signup,
    why,
    social,
    explainer,
    newsletter_signup,
    roadmap,
    about,
    contact,
  } = data.allHomeYaml.nodes[0]

  return (
    <Layout>
      <Seo title="Home" />
      <Hero data={hero} />
      <Info data={info} />
      <BetaSignup data={beta_signup} />
      <Why data={why} />
      <Social data={social} />
      <Explainer data={explainer} />
      <NewsletterSignup data={newsletter_signup} />
      {/* <Roadmap data={roadmap} /> */}
      <About data={about} />
      <Contact data={contact} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    allHomeYaml {
      nodes {
        about {
          content
          headline
        }
        beta_signup {
          cta
          email_label
          headline
        }
        contact {
          headline
          paragraph
        }
        explainer {
          headline
          share {
            headline
          }
          slides {
            paragraph
            title
          }
        }
        hero {
          blank1
          blank2
          headline
          subheadline
        }
        info {
          headline
          paragraph
        }
        newsletter_signup {
          cta
          email_label
          headline
        }
        roadmap {
          headline
          phases {
            headline
            paragraph
            milestones {
              date
              paragraph
            }
          }
        }
        social {
          headline
        }
        why {
          cards {
            description
            title
            icon
          }
          headline
        }
      }
    }
  }
`
