import React from "react"
import tw from "twin.macro"
import Container from "../Container"
import SocialLinks from "../SocialLinks"

const Social = ({ data }) => {
  return (
    <section
      css={[
        tw`bg-gradient-to-tr via-secondary from-blue to-green transform skew-y-3 py-16 md:py-24 -mb-10 animate-gradient-x`,
      ]}
    >
      <Container wide css={[tw`transform -skew-y-3`]}>
        <h2
          css={[tw`text-center mb-8 md:mb-12`]}
          data-sal="slide-up"
          data-sal-duration={400}
          data-sal-ease="ease"
        >
          {data.headline}
        </h2>
        <div
          css={[tw`flex justify-center items-center`]}
          data-sal="slide-up"
          data-sal-duration={400}
          data-sal-delay={200}
          data-sal-ease="ease"
        >
          <SocialLinks large />
        </div>
      </Container>
    </section>
  )
}

export default Social
